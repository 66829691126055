/**
 * Copyright ©2024 Drivepoint
 */

import React, {forwardRef, ReactNode, useImperativeHandle, useState} from "react";
import {Avatar, Box, Divider, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Typography} from "@mui/material";
import Icon from "@mui/material/Icon";
import "./DPPageStatus.css";

type Action = {
  title: string;
  icon?: string;
  notHideAfter?: boolean
  click: (event?: any) => unknown
};

type Variant =  "success" | "warning" | "error";

type DPPageStatusProps = {};

type DPPageStatusRefProps = {
  alert?: ReactNode;
  variant?: Variant
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  actions?: Action[];
};

export type DPPageStatusInterface = {
  show: (props: DPPageStatusRefProps) => void
  hide: () => void
};

function PageStatus(props: DPPageStatusProps, ref: any) {

  const [visible, setVisible] = useState(false);
  const [context, setContext] = useState<DPPageStatusRefProps>({});

  useImperativeHandle(ref, (): DPPageStatusInterface => ({show, hide}));

  function show(props: DPPageStatusRefProps) {
    setVisible(true);
    if (props) { setContext(props); }
  }

  function hide() {
    setVisible(false);
  }

  function renderColorForIcon(variant: Variant) {
    switch (variant) {
      case "success":
        return ["var(--GREEN-100)", "var(--GREEN-500)"];
      case "warning":
        return ["var(--ORANGE-100)", "var(--ORANGE-500)"];
      case "error":
        return ["var(--RED-100)", "var(--RED-500)"];
    }
  }

  function renderIconName(variant: Variant) {
    switch (variant) {
      case "success":
        return "check";
      case "warning":
        return "warning_amber";
      case "error":
        return "error_outline";
    }
  }

  function renderAlert() {
    return <Grid item xs={12}>{context.alert}</Grid>;
  }

  function renderIcon() {

    const [background, color] = renderColorForIcon(context.variant ?? "success");
    const iconName = renderIconName(context.variant ?? "success");

    return <Grid item xs={12} justifyContent="center" display="flex">
      <Avatar style={{background}}><Icon style={{color}}>{iconName}</Icon></Avatar>
    </Grid>;
  }

  function renderTitle() {
    return <Grid item xs={12} textAlign="center">
      <Typography variant="h6">{context.title ?? "Success"}</Typography>
    </Grid>;
  }

  function renderSubTitle() {
    return <Grid item xs={12} textAlign="center">
      {context.subtitle && context.subtitle}
      {!context.subtitle && <Typography variant="body2" style={{color: "var(--GREY-500)"}}>Successfully Updated</Typography>}
    </Grid>;
  }

  function renderActions() {
    return <Grid item xs={12}>
      <MenuList>
        {context.actions && context.actions.map((item, index) =>
          <Box key={item.title}>
            <MenuItem onClick={event => {
              item.click(event);
              if (!item.notHideAfter) { hide(); }
            }}>
              <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
            {index !== (context.actions.length - 1) && <Divider/>}
          </Box>)}
      </MenuList>
    </Grid>;
  }

  if (!visible) { return; }

  return <Box className="dp-page-status-container">
    {context.alert && <Grid p={2} height={1 / 3} alignItems={context.alert ? "flex-start" : "center"} container>{renderAlert()}</Grid>}
    <Grid container p={2} alignItems="center">
      {renderIcon()}
      {renderTitle()}
      {renderSubTitle()}
      {renderActions()}
    </Grid>
  </Box>;
}

const DPPageStatus = forwardRef<DPPageStatusInterface, DPPageStatusProps>(PageStatus);

export default DPPageStatus;
