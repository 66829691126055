/**
 * Copyright ©2024 Drivepoint
 */

import dayjs from "dayjs";
import advanced from "dayjs/plugin/advancedFormat";
import minMax from "dayjs/plugin/minMax";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ModalProvider from "mui-modal-provider";
import {createRoot} from "react-dom/client";
import {HashRouter} from "react-router-dom";
import {Config, EventBus, Logger, State} from "@bainbridge-growth/node-frontend";
import App from "./components/App/App.tsx";
import Licenses from "./licenses.ts";
import FeatureFlagsService from "./services/FeatureFlagService.ts";
import Firebase from "./services/Firebase.ts";
import LaunchDarklyClient from "./services/LaunchDarklyService.ts";
import ExcelEvents from "./services/microsoft/ExcelEvents.ts";
import ExcelFrameInfo from "./services/microsoft/ExcelFrameInfo.ts";
import ModelSettings from "./services/microsoft/ModelSettings.ts";
import ServerSentEventService from "./services/ServerSentEventService.ts";
import Telemetry from "./services/telemetry/Telemetry.ts";
import WebAppServerClient from "./services/WebAppServerClient.ts";
import ExcelUtilities from "./utilities/ExcelUtilities.ts";
import VisibilityModeChangedMessage = Office.VisibilityModeChangedMessage;
import "./index.css";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(minMax);
dayjs.extend(relativeTime);

declare global { interface Window {logger: Logger} }
declare global { let logger: Logger; }

Logger.start();
window.logger = Logger.logger;

State.set("addin:visibility", "loading");
State.set("office:ready", false);

createRoot(document.getElementById("container") as HTMLElement).render(
  <ModalProvider>
    <HashRouter>
      <App />
    </HashRouter>
  </ModalProvider>
);

Office.onReady(async () => {
  await Office.addin.setStartupBehavior(Office.StartupBehavior.load);
  Licenses.init();
  await ModelSettings.start();
  await ExcelFrameInfo.start();
  await Firebase.start();
  await LaunchDarklyClient.start();
  await FeatureFlagsService.start();
  await ExcelEvents.start();
  await ServerSentEventService.start();
  await Telemetry.start();
  logger.debug("documentRelativePath", ExcelFrameInfo.documentRelativePath);
  State.set("office:ready", true);
  Office.addin.onVisibilityModeChanged((message: VisibilityModeChangedMessage) => {
    State.set("addin:visibility", message.visibilityMode === "Taskpane" ? "visible" : "hidden");
  });
  await Office.addin.showAsTaskpane();
  State.set("addin:visibility", "visible");
  const modelSettings = await ModelSettings.get();
  State.set("modelSettings", modelSettings);
});

async function showPage(event: any): Promise<void> {
  try {
    await Office.addin.showAsTaskpane();
    Telemetry.track("click_ribbon_menu", {menu_item: event?.source?.id});
    EventBus.dispatch({type: "show:page", id: `/${event?.source?.id}`} as any);
  } catch (error) {
    logger.error(error.message);
  } finally {
    event.completed();
  }
}

async function openTab(event: any): Promise<void> {
  try {
    Telemetry.track("click_ribbon_menu", {menu_item: event?.source?.id});
    EventBus.dispatch({type: "open:tab", id: `/${event?.source?.id}`} as any);
    switch (event?.source?.id) {
      case "HelpLink":
        window.open("https://docs.drivepoint.io/help", "_blank");
        break;
      case "BrowsePlans":
        const settings = await ModelSettings.get();
        window.open(`${Config.get("webapp.url")}/${settings?.companyId ? `${settings.companyId}/plans` : ""}`, "_blank");
        break;
    }
  } catch (error) {
    logger.error(error.message);
  } finally {
    event.completed();
  }
}

async function syncPlan(event: any) {
  await Office.addin.showAsTaskpane();
  const user = State.get("user");

  if (user) {
    try {
      Telemetry.track("click_ribbon_menu", {menu_item: event?.source?.id});
      await ExcelUtilities.toggleButtonState("Sync", false);
      const settings = await ModelSettings.get();
      await WebAppServerClient.request(`/api/company/${settings.companyId}/plan/${settings.sharepointItemId}/sync`, "PATCH");
    } catch (err) { logger.error(err.message); } finally {
      await ExcelUtilities.toggleButtonState("Sync");
      event.completed();
    }
  } else {
    EventBus.dispatch({type: "notification:error", message: "Sign in first."} as any);
    event.completed();
  }
}

Office.actions.associate("showPage", showPage);
Office.actions.associate("openTab", openTab);
Office.actions.associate("syncPlan", syncPlan);
