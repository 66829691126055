/**
 * Copyright ©2024 Drivepoint
 */

import {useForm} from "react-hook-form";
import React, {useEffect, useRef, useState} from "react";
import {Box, Grid, Icon, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Utilities} from "@bainbridge-growth/node-common";
import {EventBus} from "@bainbridge-growth/node-frontend";
import DPButton from "../../components/DPButton/DPButton.tsx";
import DPPageLoadingVeil, {DPPageLoadingVeilInterface} from "../../components/DPPageLoadingVeil/DPPageLoadingVeil.tsx";
import DPPageStatus, {DPPageStatusInterface} from "../../components/DPPageStatus/DPPageStatus.tsx";
import ErrorHandling from "../../utilities/ErrorHandling.ts";
import {signInWithCredentials, signInWithGoogle} from "./logic.ts";
import BackgroundPattern1 from "../../../assets/background-pattern-1.svg";
import MainLogoWithoutText from "../../../assets/drivepoint.png";
import DPPattern from "../../../assets/drivepoint-logo-pattern.svg";
import MainLogo from "../../../assets/drivepoint-logo-primary.png";
import GoogleLogo from "../../../assets/google-logo.png";
import "./SignInPage.css";

type CurrentState = "main" | "sign_in_with_email" | "sign_up";
type SignInFormValues = {
  email: string;
  password: string;
};

export default function SignInPage() {

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors}
  } = useForm<SignInFormValues>({
    defaultValues: {email: "", password: ""}
  });
  const [currentState, setCurrentState] = useState<CurrentState>("main");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const loader = useRef<DPPageLoadingVeilInterface>();
  const status = useRef<DPPageStatusInterface>();

  useEffect(() => {
    const registrations = EventBus.registerMany("user:loading", "user:loaded", onEvent);
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  function onEvent(event: any): void {
    if (event.type === "user:loading") { loader.current.show("Signing in"); }
    if (event.type === "user:loaded") { loader.current.hide(); }
  }

  function handleErrorStatus() {
    status.current.show({
      variant: "error",
      title: "Something went wrong!",
      subtitle: "Please try again. If the issue persists please contact support.",
      actions: [
        {
          icon: "refresh",
          title: "Refresh Add-in",
          click: () => window.location.reload()
        },
        {icon: "support_agent", title: "Contact Support", click: ErrorHandling.contactSupport, notHideAfter: true}
      ]
    });
  }

  function togglePassword() {
    setShowPassword(prev => !prev);
  }

  function handleSignInWithCredentialsErrors(err: Error) {
    if (err.message.includes("email") || err.message.includes("password")) {
      setError(
        "email",
        {message: "The email or password you entered did not match our records. Please double check and try again."}
      );
      return;
    }

    handleErrorStatus();

  }

  async function handleFormSubmit(values: SignInFormValues) {
    clearErrors();
    if (values.email.trim() === "") {
      setError("email", {message: "Email field is required"});
      return;
    }
    if (values.password.trim() === "") {
      setError("password", {message: "Password field is required"});
      return;
    }
    await signInWithCredentials({values, onError: handleSignInWithCredentialsErrors});
  }

  async function handleGoogleSignIn() {
    await signInWithGoogle({
      onError: () => { handleErrorStatus(); }
    });
  }

  function renderMain() {
    return <Grid item xs={10}>
      <Grid container className="dp-sign-in-main">
        <Grid item xs={12} textAlign="center" mb={2}>
          <img alt="main logo" src={MainLogo} height="46px" />
        </Grid>
        <Grid item xs={12} textAlign="center" mb={2}>
          <Typography variant="body1">The Strategic Finance Platform for Scaling Brands.</Typography>
        </Grid>
        {Office.context.platform === Office.PlatformType.OfficeOnline && <Grid item xs={12} mb={1}>
          <DPButton
            onClick={handleGoogleSignIn}
            className="dp-sign-in-main-google-btn"
            label={<><img height="22px" style={{marginRight: "8px"}} src={GoogleLogo} alt="google icon"/> Sign In With
                Google</>}
            variant="outlined"
            fullWidth/>
        </Grid>}
        <Grid item xs={12} textAlign="center" mb={3}>
          <DPButton label="Email Sign In" variant="contained" fullWidth onClick={() => { setCurrentState("sign_in_with_email"); }} />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="body2" style={{color: "var(--GREY-600)"}}>
              Don’t have an account?
            <span
              className="dp-sign-in-main-link"
              onClick={() => { setCurrentState("sign_up"); }}>Sign up now.</span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>;
  }
  function renderSignInWithEmail() {
    return <Grid item xs={10}>
      <Grid container className="dp-sign-in-form-container">
        <Grid item xs={12} textAlign="center" mb={2}><img alt="main logo" src={MainLogo} height="46px" /></Grid>
        <Grid item xs={12} textAlign="center" mb={2}><Typography variant="body1">Sign in to Drivepoint</Typography></Grid>
        <Grid item xs={12}>
          <Grid item xs={12} mb={2}>
            <TextField
              error={!Utilities.isEmpty(errors)}
              fullWidth
              InputLabelProps={{shrink: true}}
              label="Email Address"
              required
              placeholder="you@company.com"
              {...register("email")} />
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextField
              error={!Utilities.isEmpty(errors)}
              fullWidth
              required
              InputProps={{endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={togglePassword}>
                    {showPassword ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                  </IconButton>
                </InputAdornment>
              }}
              InputLabelProps={{shrink: true}}
              type={showPassword ? "text" : "password"}
              label="Password"
              {...register("password")} />
          </Grid>
          {Object.keys(errors).length > 0 && Object.keys(errors).map(key =>
            <Grid item xs={12} mb={2}>
              <span className="dp-sign-in-form-error-text">{errors[key].message}</span>
            </Grid>
          )}
          <Grid item xs={12} mb={2}>
            <Typography variant="body2">
              <span onClick={() => {}} className="dp-sign-in-form-link">Forgot Password</span>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <DPButton fullWidth onClick={handleSubmit(handleFormSubmit)} label="Sign In" />
          </Grid>
          {Office.context.platform === Office.PlatformType.OfficeOnline && <Grid item xs={12} mb={1}>
            <Typography variant="body2">
              Sign in using <span className="dp-sign-in-form-link" onClick={handleGoogleSignIn}>Google</span>
            </Typography>
          </Grid>}
          <Grid item xs={12}>
            <Typography variant="body2">
              Don’t have an account? <span className="dp-sign-in-form-link" onClick={() => { setCurrentState("sign_up"); }}>Sign up now</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;
  }

  function renderSignUp() {
    return <Grid item xs={10}>
      <Grid container className="dp-sign-up">
        <Grid item xs={12} mb={1}><img src={MainLogoWithoutText} height="51px" alt="main logo" /></Grid>
        <Grid item xs={12} mb={2}><Typography variant="h6">Welcome!</Typography></Grid>
        <Grid item xs={12} mb={2}>
          <Typography variant="body1">
            It appears this is your first time using Drivepoint.
            Schedule a free demo today so we can setup your account.
          </Typography>
        </Grid>
        <Grid item xs={12} mb={1}>
          <DPButton
            label="Schedule Demo"
            variant="contained"
            onClick={() => { window.open("https://www.drivepoint.io/demo", "_blank"); }}
            fullWidth />
        </Grid>
        <Grid item xs={12} mb={1}>
          <DPButton
            label="About Drivepoint"
            variant="outlined"
            onClick={() => { window.open("https://www.drivepoint.io/platform", "_blank"); }}
            fullWidth />
        </Grid>
        <Grid item xs={12} mb={1} textAlign="center">
          <Typography variant="body2">
            <span className="dp-sign-up-link" onClick={() => { setCurrentState("main"); }}>Sign In with a different account</span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>;
  }

  function renderCurrentState() {
    switch (currentState) {
      case "sign_in_with_email":
        return renderSignInWithEmail();
      case "sign_up":
        return renderSignUp();
      case "main":
        return renderMain();
    }
  }

  function renderBackground() {
    return <>
      <img style={{position: "absolute", bottom: "-230px", right: "-35px"}} alt="dp-pattern" src={DPPattern}/>
      <img style={{position: "absolute", left: "-560px", top: "-560px"}} alt="dp-pattern" src={BackgroundPattern1}/>
      <img style={{position: "absolute", right: "-753px", top: "-336px"}} alt="dp-pattern" src={BackgroundPattern1}/>
    </>;
  }

  return <Box height={1} style={{background: "var(--GREY-0)"}} className="dp-sign-in-container">
    <DPPageLoadingVeil ref={loader} />
    <DPPageStatus ref={status} />
    {renderBackground()}
    <Grid style={{position: "absolute"}} container justifyContent="center" alignItems="center" height={1}>
      {renderCurrentState()}
    </Grid>
  </Box>;
}
