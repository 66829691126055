/**
 * Copyright ©2024 Drivepoint
 */

import {Config} from "@bainbridge-growth/node-frontend";

export default class ErrorHandling {

  static getHumanReadableErrorMessage(error: any): string {
    switch (error?.code) {
      case 404:
      case "ItemNotFound":
        return error?.tab ? `Could not find the ${error.tab} tab` : "Could not find a required item";
      default:
        logger.debug("unhandled error", error);
        return "An unknown error occurred";
    }
  }

  static contactSupport(event: any): void {
    event?.preventDefault();
    event?.stopPropagation();
    window.open(Config.get("urls.customer_support"), "_blank");
  }

}
