/**
 * Copyright ©2024 Drivepoint
 */

import React from "react";

const HomePage = React.lazy(() => import("../pages/HomePage/HomePage.tsx"));
const ImportDataPage = React.lazy(() => import("../pages/ImportDataPage/ImportDataPage.tsx"));
const ModelsPage = React.lazy(() => import("../pages/ModelsPage/ModelsPage.tsx"));
const SignInPage = React.lazy(() => import("../pages/SignInPage/SignInPage.tsx"));
const ModelRollForwardPage = React.lazy(() => import("../pages/ModelRollForwardPage/ModelRollForwardPage.tsx"));
const NewPlanPage = React.lazy(() => import("../pages/NewPlanPage/NewPlanPage.tsx"));
const VarianceReviewPage = React.lazy(() => import("../pages/VarianceReviewPage/VarianceReviewPage.tsx"));

export default class Pages {

  // the id here must match the "Control" id in manifest.xml
  private static _pages: any[] = [
    {id: "HomePage", name: "Home", path: "/HomePage", element: HomePage},
    {id: "SignInPage", name: "SignIn", path: "/SignInPage", element: SignInPage},
    {id: "ModelsPage", name: "Plans", path: "/ModelsPage", element: ModelsPage},
    {id: "ImportDataPage", name: "Import Data", path: "/ImportDataPage", element: ImportDataPage},
    {id: "RollForwardPage", name: "Roll Forward", path: "/RollForwardPage", element: ModelRollForwardPage},
    {id: "RollBackPage", name: "Roll Back", path: "/RollBackwardPage", element: ModelRollForwardPage},
    {id: "NewPlanPage", name: "Create New Plan", path: "/NewPlanPage", element: NewPlanPage},
    {id: "VarianceReviewPage", name: "Import Plans", path: "/VarianceReviewPage", element: VarianceReviewPage}
  ];

  static getPageByPath(path: string): any {
    return Pages.pages.find(it => it.path === path);
  }

  static get default(): any {
    return Pages.pages?.[0];
  }

  static get pages(): any[] {
    return Pages._pages;
  }

}
