/**
  * Copyright ©2024 Drivepoint
  */

import {State, StateChangeEvent, Store} from "@bainbridge-growth/node-frontend";
import NotFoundError from "../errors/NotFoundError.ts";
import WebAppServerClient from "../services/WebAppServerClient.ts";

export default class DataFreshnessStore extends Store {

  private _timeout: any;

  init(): void {
    this.loading = true;
    State.registerMany("company", (event: StateChangeEvent) => {
      this.value = [];
      this._throttledRefresh();
    });
  }

  async refresh(): Promise<void> {
    clearTimeout(this._timeout);
    return this.action(async () => {
      if (!this.company) { throw new NotFoundError(); }
      try {
        const res = await WebAppServerClient.request(`/ui/company/${this.company.id}/dataStatus`, "GET");
        this.value = res?.data ?? [];
      } catch (error: any) {
        this.value = [];
        logger.error(error.message);
      }
    });
  }

  private async _throttledRefresh(): Promise<void> {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.refresh();
    }, 1500);
  }

  get company(): any {
    return State.get("company");
  }

}
