import {Source} from "../../types/DataSource.ts";
import Amazon from "../../../assets/brands/amazon.svg";
import MuffinData from "../../../assets/brands/muffin_data.svg";
import Netsuite from "../../../assets/brands/netsuite.svg";
import Quickbooks from "../../../assets/brands/quickbooks.svg";
import Shopify from "../../../assets/brands/shopify.svg";
import Dp from "../../../assets/drivepoint.png";

type DataSource = {
  id: Source,
  name: string, 
  icon: string,
  status?: string,
  refreshedAt?: string
};

const dataSourcesMap: Record<Source, DataSource>  = {
  shopify: {id: "shopify", name: "Shopify", icon: Shopify},
  netsuite: {id: "netsuite", name: "Netsuite", icon: Netsuite},
  quickbooks: {id: "quickbooks", name: "Quickbooks", icon: Quickbooks},
  amazon: {id: "amazon", name: "Amazon Seller", icon: Amazon},
  drivepoint_ecommerce: {id: "drivepoint_ecommerce", name: "Drivepoint E-Commerce", icon: Dp},
  muffindata: {id: "muffindata", name: "Muffin Data", icon: MuffinData}
};
export default dataSourcesMap;