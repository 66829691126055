/**
 * Copyright ©2024 Drivepoint
 */

import {Mutex} from "async-mutex";
import {compareVersions} from "compare-versions";
import * as Sentry from "@sentry/react";
import Constants from "./Constants.ts";
import {IRangeData} from "./DataObjects";
import ExcelUtilities from "./ExcelUtilities.ts";
import Worksheet = Excel.Worksheet;

const _settingsContext: any = {
  mutex: new Mutex(),
  value: undefined,
  timestamp: 0
};

/*
    Take a cell and move the appropriate letter value
    works with a full address ' sheet foo !AZ1'  or just AZ1
*/

export const advanceCellLettersOrNumbers = (addressString, incrementLetterCount, incrementNumberCount) => {
  let returnAddress = "";
  // convert a full address to just a 2 to 8 character version A1 or ZQ65525
  const firstPass = addressString.match(/!(\w{1,}\d{1,})/);
  if (firstPass && firstPass[1]) {
    addressString = firstPass[1];
  } else {
    // logger.debug("could not find cell initially from:"+addressString);
  }
  let letters = "";
  let numbers;
  const foundSimple = addressString.match(/([A-Za-z]*)(\d*)/);
  if (foundSimple && foundSimple[1] && foundSimple[2]) {
    letters = foundSimple[1];
    numbers = foundSimple[2];
  } else {
    logger.debug("could not find cell fully from:" + addressString);
    return returnAddress;
  }
  // convert to a number, add the increment, convert it back
  if (incrementLetterCount && incrementLetterCount != 0) {
    let lettercount = letters.length;
    let letterSum = incrementLetterCount;
    if (lettercount == 1) {
      letterSum = letterSum + Constants.lettersToNumbers[letters[0]];
    } else if (lettercount == 2) {
      for (let i = lettercount - 1; i >= 0; i--) {
        if (i == 1) {
          letterSum = letterSum + Constants.lettersToNumbers[letters[i]];
        } else if (i == 0) {
          letterSum = letterSum + 26 * (Constants.lettersToNumbers[letters[i]] + 1); // zero based, so add one
        } else if (i == 3) {
          logger.debug("ERROR OUT OF RANGE");
          // letterSum = letterSum+ 26*26*lettersToNumbers[letters[i]] ;
        } else {
          logger.debug("ERROR OUT OF RANGE");
        }
      }
    } else {
      logger.debug("ERROR OUT OF RANGE, 3 digits not supported");
    }
    // we only support 2 letter codes here..
    let letterSumFactor = Math.floor(letterSum / 26);
    let letterSumRemainder = letterSum % 26; // remainder of 26 here means 'A'
    if (letterSumFactor == 0) {
      letters = Constants.numbersToLetters[letterSumRemainder];
    } else {
      letters = Constants.numbersToLetters[letterSumFactor - 1] + Constants.numbersToLetters[letterSumRemainder];
    }
  }
  if (incrementNumberCount && incrementNumberCount != 0) {
    numbers = parseInt(numbers) + parseInt(incrementNumberCount);
  }
  return `${letters}${numbers}`;
};

export const isInvalidCellValue = (value: any): boolean => {
  if (typeof value !== "string") { return false; }
  return ["#REF!", "#DIV/0!", "#VALUE!", "#NAME?", "#N/A", "#NUM", "#NULL", "####", "#SPILL!", "#CALC!"].includes(value);
};

export const pivotDataForExecDash = (dataExcelModel, sheetName: string, companyId: string, settings: any) => {
  let pivotedDataExcelModel = {};
  let pivotedDataExcelModelReturn = [];
  let pivotedDataExcelModelFinal = [];
  try {
    logger.debug(`pivotDataForExecDash going to pivot ${Object.keys(dataExcelModel).length} keys`);
    for (const metricName in dataExcelModel) {
      if (dataExcelModel.hasOwnProperty(metricName)) {
        pivotedDataExcelModel[metricName] = []; // was {}
        if (["modelStartYear", "modelStartDateInteger", "modelStartDateString", "threeYearPlanStartYear", "threeYearPlanStartDateInteger", "threeYearPlanStartDateString", ""
        ].includes(metricName)) {
          // not sure this is useful here
          // if ( dataExcelModel[metricName] == 'threeYearPlanStartDateString' ) {
          //     dateShiftedDataExcelModel['metadata']['modelStartDateForUI'] = dataExcelModel[metricName] ;
          // } else if (dataExcelModel[metricName] == 'threeYearPlanStartDateInteger' ){
          // }
          delete pivotedDataExcelModel[metricName];
        } else {
          // const monthlyRows: IMonthDataObject[] = dataExcelModel[metricName]['months']['childArray'];
          const monthlyRows = dataExcelModel[metricName]["months"]["childArray"];
          // monthlyRows.forEach((monthRow) => {
          for (const monthRowKey in monthlyRows) {
            if (monthlyRows.hasOwnProperty(monthRowKey)) {
              let monthRow = monthlyRows[monthRowKey];
              if (typeof metricName === "number" ||
                                ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"].includes(metricName)
              ) { // some of the keys are numbers like 1-20 due the inventory schedule, get rid of that crap
                continue; // wow, had a return here, which causes it all to blow up and return no data..
              }
              let value = monthRow.valueSheet;
              // these lines work here
              if (pivotedDataExcelModel && pivotedDataExcelModel[monthRow.yearMonthNumeric]) {
              } else {
                pivotedDataExcelModel[monthRow.yearMonthNumeric] = {};
                pivotedDataExcelModel[monthRow.yearMonthNumeric]["yearMonthNumeric"] = monthRow.yearMonthNumeric; // set a specific date row here..
              }
              if (typeof value === "number") {
                pivotedDataExcelModel[monthRow.yearMonthNumeric][metricName] = Math.round((value + Number.EPSILON) * 100) / 100;
              } else if (isInvalidCellValue(value)) { // broken links, make them zeros
                pivotedDataExcelModel[monthRow.yearMonthNumeric][metricName] = 0;
              } else {
                pivotedDataExcelModel[monthRow.yearMonthNumeric][metricName] = value;
              }
            }
          }
        }
      }
    }
    Object.keys(pivotedDataExcelModel).forEach((k) => {
      if (!(
        Constants.metricsArray.includes(pivotedDataExcelModel[k]) ||
                pivotedDataExcelModel[k] == "excelDate" ||
                pivotedDataExcelModel[k] == "yearMonthNumeric" ||
                pivotedDataExcelModel[k] == "" ||
                !Object.keys(pivotedDataExcelModel[k]).length
      )) {
        pivotedDataExcelModelFinal.push(pivotedDataExcelModel[k]);
      }
    });
    for (const key in pivotedDataExcelModelFinal) {
      if (key && key != "" && Object.keys(pivotedDataExcelModelFinal[key]).length) {
        // /database fields cannot have periods just underscores, letters, so swap em out here..
        pivotedDataExcelModelReturn.push({
          id: pivotedDataExcelModelFinal[key]["yearMonthNumeric"],
          yearMonthNumeric: pivotedDataExcelModelFinal[key]["yearMonthNumeric"],
          "customMetric1": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric1"]),
          "customMetric2": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric2"]),
          "customMetric3": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric3"]),
          "customMetric4": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric4"]),
          "customMetric5": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric5"]),
          "customMetric6": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric6"]),
          "customMetric7": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric7"]),
          "customMetric8": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric8"]),
          "customMetric9": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric9"]),
          "customMetric10": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["customMetric10"]),
          "incomeStatement_grossSales": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossSales"]),
          "incomeStatement_grossSales_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossSales.dtcOnline"]),
          "incomeStatement_grossSales_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossSales.marketplace"]),
          "incomeStatement_grossSales_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossSales.wholesale"]),
          "incomeStatement_grossSales_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossSales.retail"]),
          "incomeStatement_discounts": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.discounts"]),
          "incomeStatement_discounts_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.discounts.dtcOnline"]),
          "incomeStatement_discounts_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.discounts.marketplace"]),
          "incomeStatement_discounts_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.discounts.wholesale"]),
          "incomeStatement_discounts_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.discounts.retail"]),
          "incomeStatement_returns": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.returns"]),
          "incomeStatement_returns_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.returns.dtcOnline"]),
          "incomeStatement_returns_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.returns.marketplace"]),
          "incomeStatement_returns_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.returns.wholesale"]),
          "incomeStatement_returns_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.returns.retail"]),
          "incomeStatement_shippingIncome": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingIncome"]),
          "incomeStatement_shippingIncome_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingIncome.dtcOnline"]),
          "incomeStatement_shippingIncome_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingIncome.marketplace"]),
          "incomeStatement_shippingIncome_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingIncome.wholesale"]),
          "incomeStatement_shippingIncome_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingIncome.retail"]),
          "incomeStatement_taxesCollected": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.taxesCollected"]),
          "incomeStatement_taxesCollected_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.taxesCollected.dtcOnline"]),
          "incomeStatement_taxesCollected_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.taxesCollected.marketplace"]),
          "incomeStatement_taxesCollected_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.taxesCollected.wholesale"]),
          "incomeStatement_taxesCollected_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.taxesCollected.retail"]),
          "incomeStatement_netRevenue": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.netRevenue"]),
          "incomeStatement_netRevenue_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.netRevenue.dtcOnline"]),
          "incomeStatement_netRevenue_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.netRevenue.marketplace"]),
          "incomeStatement_netRevenue_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.netRevenue.wholesale"]),
          "incomeStatement_netRevenue_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.netRevenue.retail"]),
          "incomeStatement_costOfGoodsSold": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.costOfGoodsSold"]),
          "incomeStatement_costOfGoodsSold_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.costOfGoodsSold.dtcOnline"]),
          "incomeStatement_costOfGoodsSold_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.costOfGoodsSold.marketplace"]),
          "incomeStatement_costOfGoodsSold_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.costOfGoodsSold.wholesale"]),
          "incomeStatement_costOfGoodsSold_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.costOfGoodsSold.retail"]),
          "incomeStatement_productCost": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.productCost"]),
          "incomeStatement_productCost_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.productCost.dtcOnline"]),
          "incomeStatement_productCost_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.productCost.marketplace"]),
          "incomeStatement_productCost_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.productCost.wholesale"]),
          "incomeStatement_productCost_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.productCost.retail"]),
          "incomeStatement_importFreight": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importFreight"]),
          "incomeStatement_importFreight_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importFreight.dtcOnline"]),
          "incomeStatement_importFreight_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importFreight.marketplace"]),
          "incomeStatement_importFreight_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importFreight.wholesale"]),
          "incomeStatement_importFreight_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importFreight.retail"]),
          "incomeStatement_importDutiesAndTaxes": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importDutiesAndTaxes"]),
          "incomeStatement_importDutiesAndTaxes_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importDutiesAndTaxes.dtcOnline"]),
          "incomeStatement_importDutiesAndTaxes_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importDutiesAndTaxes.marketplace"]),
          "incomeStatement_importDutiesAndTaxes_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importDutiesAndTaxes.wholesale"]),
          "incomeStatement_importDutiesAndTaxes_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.importDutiesAndTaxes.retail"]),
          "incomeStatement_grossProfit": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossProfit"]),
          "incomeStatement_grossProfit_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossProfit.dtcOnline"]),
          "incomeStatement_grossProfit_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossProfit.marketplace"]),
          "incomeStatement_grossProfit_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossProfit.wholesale"]),
          "incomeStatement_grossProfit_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.grossProfit.retail"]),
          "incomeStatement_fulfillmentCosts": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.fulfillmentCosts"]),
          "incomeStatement_fulfillmentCosts_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.fulfillmentCosts.dtcOnline"]),
          "incomeStatement_fulfillmentCosts_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.fulfillmentCosts.marketplace"]),
          "incomeStatement_fulfillmentCosts_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.fulfillmentCosts.wholesale"]),
          "incomeStatement_fulfillmentCosts_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.fulfillmentCosts.retail"]),
          "incomeStatement_otherVariableCosts": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherVariableCosts"]),
          "incomeStatement_otherVariableCosts_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherVariableCosts.dtcOnline"]),
          "incomeStatement_otherVariableCosts_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherVariableCosts.marketplace"]),
          "incomeStatement_otherVariableCosts_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherVariableCosts.wholesale"]),
          "incomeStatement_otherVariableCosts_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherVariableCosts.retail"]),
          "incomeStatement_shippingExpense": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingExpense"]),
          "incomeStatement_shippingExpense_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingExpense.dtcOnline"]),
          "incomeStatement_shippingExpense_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingExpense.marketplace"]),
          "incomeStatement_shippingExpense_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingExpense.wholesale"]),
          "incomeStatement_shippingExpense_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.shippingExpense.retail"]),
          "incomeStatement_merchantFees": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.merchantFees"]),
          "incomeStatement_merchantFees_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.merchantFees.dtcOnline"]),
          "incomeStatement_merchantFees_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.merchantFees.marketplace"]),
          "incomeStatement_merchantFees_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.merchantFees.wholesale"]),
          "incomeStatement_merchantFees_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.merchantFees.retail"]),
          "incomeStatement_variableExpenses": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.variableExpenses"]),
          "incomeStatement_variableExpenses_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.variableExpenses.dtcOnline"]),
          "incomeStatement_variableExpenses_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.variableExpenses.marketplace"]),
          "incomeStatement_variableExpenses_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.variableExpenses.wholesale"]),
          "incomeStatement_variableExpenses_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.variableExpenses.retail"]),
          "incomeStatement_contributionProfit": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contributionProfit"]),
          "incomeStatement_contributionProfit_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contributionProfit.dtcOnline"]),
          "incomeStatement_contributionProfit_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contributionProfit.marketplace"]),
          "incomeStatement_contributionProfit_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contributionProfit.wholesale"]),
          "incomeStatement_contributionProfit_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contributionProfit.retail"]),
          "incomeStatement_directAdvertising": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.directAdvertising"]),
          "incomeStatement_directAdvertising_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.directAdvertising.dtcOnline"]),
          "incomeStatement_directAdvertising_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.directAdvertising.marketplace"]),
          "incomeStatement_directAdvertising_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.directAdvertising.wholesale"]),
          "incomeStatement_directAdvertising_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.directAdvertising.retail"]),
          "incomeStatement_otherAdvertising": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherAdvertising"]),
          "incomeStatement_otherAdvertising_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherAdvertising.dtcOnline"]),
          "incomeStatement_otherAdvertising_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherAdvertising.marketplace"]),
          "incomeStatement_otherAdvertising_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherAdvertising.wholesale"]),
          "incomeStatement_otherAdvertising_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherAdvertising.retail"]),
          "incomeStatement_otherMarketing": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherMarketing"]),
          "incomeStatement_otherMarketing_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherMarketing.dtcOnline"]),
          "incomeStatement_otherMarketing_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherMarketing.marketplace"]),
          "incomeStatement_otherMarketing_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherMarketing.wholesale"]),
          "incomeStatement_otherMarketing_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherMarketing.retail"]),
          "incomeStatement_marketingAgency": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.marketingAgency"]),
          "incomeStatement_marketingAgency_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.marketingAgency.dtcOnline"]),
          "incomeStatement_marketingAgency_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.marketingAgency.marketplace"]),
          "incomeStatement_marketingAgency_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.marketingAgency.wholesale"]),
          "incomeStatement_marketingAgency_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.marketingAgency.retail"]),
          "incomeStatement_totalMarketingExpenses": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.totalMarketingExpenses"]),
          "incomeStatement_totalMarketingExpenses_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.totalMarketingExpenses.dtcOnline"]),
          "incomeStatement_totalMarketingExpenses_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.totalMarketingExpenses.marketplace"]),
          "incomeStatement_totalMarketingExpenses_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.totalMarketingExpenses.wholesale"]),
          "incomeStatement_totalMarketingExpenses_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.totalMarketingExpenses.retail"]),
          "incomeStatement_payroll_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.payroll.dtcOnline"]),
          "incomeStatement_payroll_currentStaffSalariesAndBenefits": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.payroll.currentStaffSalariesAndBenefits"]),
          "incomeStatement_payroll_bonusPayouts": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.payroll.bonusPayouts"]),
          "incomeStatement_payroll_newStaffSalariesAndBenefits": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.payroll.newStaffSalariesAndBenefits"]),
          "incomeStatement_legalAndProfessional": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.payroll.legalAndProfessional"]),
          "incomeStatement_contractors": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contractors"]),
          "incomeStatement_contractors_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contractors.dtcOnline"]),
          "incomeStatement_contractors_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contractors.marketplace"]),
          "incomeStatement_contractors_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contractors.wholesale"]),
          "incomeStatement_contractors_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.contractors.retail"]),
          "incomeStatement_peopleCosts": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.peopleCosts"]),
          "incomeStatement_generalAndAdministrative": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.generalAndAdministrative"]),
          "incomeStatement_researchAndDevelopment": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.researchAndDevelopment"]),
          "incomeStatement_depreciation": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.depreciation"]),
          "incomeStatement_amortization": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.amortization"]),
          "incomeStatement_otherOperatingExpenses": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherOperatingExpenses"]),
          "incomeStatement_operatingExpenses": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.operatingExpenses"]),
          "incomeStatement_operatingIncome": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.operatingIncome"]),
          "incomeStatement_otherExpenses": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherExpenses"]),
          "incomeStatement_otherIncome": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.otherIncome"]),
          "incomeStatement_interestExpense": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.interestExpense"]),
          "incomeStatement_preTaxIncome": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.preTaxIncome"]),
          "incomeStatement_incomeTaxExpense": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.incomeTaxExpense"]),
          "incomeStatement_netIncome": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.netIncome"]),
          "incomeStatement_EBITDA": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["incomeStatement.EBITDA"]),
          "balanceSheet_currentAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.currentAssets"]),
          "balanceSheet_cash": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.cash"]),
          "balanceSheet_accountsReceivable": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.accountsReceivable"]),
          "balanceSheet_inventory_finishedGoodsInTransit": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.inventory.finishedGoodsInTransit"]),
          "balanceSheet_inventory_finishedGoodsInWarehouse": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.inventory.finishedGoodsInWarehouse"]),
          "balanceSheet_inventory": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.inventory"]),
          "balanceSheet_workInProgress": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.workInProgress"]),
          "balanceSheet_otherCurrentAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.otherCurrentAssets"]),
          "balanceSheet_totalCurrentAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.totalCurrentAssets"]),
          "balanceSheet_fixedAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.fixedAssets"]),
          "balanceSheet_accumulatedAmortization": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.accumulatedAmortization"]),
          "balanceSheet_prepaidCapitalFees": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.prepaidCapitalFees"]),
          "balanceSheet_otherAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.otherAssets"]),
          "balanceSheet_totalAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.totalAssets"]),
          "balanceSheet_currentLiabilities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.currentLiabilities"]),
          "balanceSheet_accountsPayable": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.accountsPayable"]),
          "balanceSheet_otherCurrentLiabilities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.otherCurrentLiabilities"]),
          "balanceSheet_lineOfCredit": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.lineOfCredit"]),
          "balanceSheet_totalCurrentLiabilities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.totalCurrentLiabilities"]),
          "balanceSheet_longTermLiabilities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.longTermLiabilities"]),
          "balanceSheet_totalLiabilities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.totalLiabilities"]),
          "balanceSheet_commonStock": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.commonStock"]),
          "balanceSheet_beginningOfPeriodPaidInCapital": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.beginningOfPeriodPaidInCapital"]),
          "balanceSheet_endOfPeriodPaidInCapital": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.endOfPeriodPaidInCapital"]),
          "balanceSheet_retainedEarnings": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.retainedEarnings"]),
          "balanceSheet_totalEquity": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["balanceSheet.totalEquity"]),
          "cashFlowStatement_netIncome": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.netIncome"]),
          "cashFlowStatement_depreciation": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.depreciation"]),
          "cashFlowStatement_amortization": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.amortization"]),
          "cashFlowStatement_changeInWorkingCapital": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.changeInWorkingCapital"]),
          "cashFlowStatement_accountsReceivable": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.accountsReceivable"]),
          "cashFlowStatement_inventory": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.inventory"]),
          "cashFlowStatement_prepaidExpense": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.prepaidExpense"]),
          "cashFlowStatement_accountsPayable": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.accountsPayable"]),
          "cashFlowStatement_otherCurrentAssets": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.otherCurrentAssets"]),
          "cashFlowStatement_otherCurrentLiabilities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.otherCurrentLiabilities"]),
          "cashFlowStatement_netCashProvidedByOperatingActivities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.netCashProvidedByOperatingActivities"]),
          "cashFlowStatement_capitalExpenditures": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.capitalExpenditures"]),
          "cashFlowStatement_netCashUsedInInvestingActivities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.netCashUsedInInvestingActivities"]),
          "cashFlowStatement_equityInvestment": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.equityInvestment"]),
          "cashFlowStatement_lineOfCredit": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.lineOfCredit"]),
          "cashFlowStatement_longTermDebt": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.longTermDebt"]),
          "cashFlowStatement_netCashProvidedByFinancingActivities": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.netCashProvidedByFinancingActivities"]),
          "cashFlowStatement_netCashFlow": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.netCashFlow"]),
          "cashFlowStatement_beginningOfPeriodCash": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.beginningOfPeriodCash"]),
          "cashFlowStatement_endOfPeriodCash": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["cashFlowStatement.endOfPeriodCash"]),
          "metrics_grossSalesGrowthPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.grossSalesGrowthPercent"]),
          "metrics_netRevenueMarginPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.netRevenueMarginPercent"]),
          "metrics_costOfGoodsSoldPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.costOfGoodsSoldPercent"]),
          "metrics_grossMarginPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.grossMarginPercent"]),
          "metrics_contributionMarginPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.contributionMarginPercent"]),
          "metrics_totalOpexPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.totalOpexPercent"]),
          "metrics_EBITDAMarginPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.EBITDAMarginPercent"]),
          "metrics_operatingMarginPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.operatingMarginPercent"]),
          "metrics_taxRatePercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.taxRatePercent"]),
          "metrics_netIncomeMarginPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.netIncomeMarginPercent"]),
          "metrics_adSpendGrowthPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.adSpendGrowthPercent"]),
          "metrics_contributionMarginAfterMarketingPercent": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.contributionMarginAfterMarketingPercent"]),
          "metrics_daysSalesOutstanding": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.daysSalesOutstanding"]),
          "metrics_daysPayableOutstanding": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.daysPayableOutstanding"]),
          "metrics_orders": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.orders"]),
          "metrics_orders_unallocated": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.orders.unallocated"]),
          "metrics_orders_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.orders.dtcOnline"]),
          "metrics_orders_marketplace": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.orders.marketplace"]),
          "metrics_orders_wholesale": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.orders.wholesale"]),
          "metrics_orders_retail": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.orders.retail"]),
          "metrics_fulfillmentCostPerOrder_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.fulfillmentCostPerOrder.dtcOnline"]),
          "metrics_shippingCostPerOrder_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.shippingCostPerOrder.dtcOnline"]),
          "metrics_merchantFeesPercent_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.merchantFeesPercent.dtcOnline"]),
          "metrics_returningCustomerAverageOrderValue_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.returningCustomerAverageOrderValue.dtcOnline"]),
          "metrics_salesFromReturningCustomers_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.salesFromReturningCustomers.dtcOnline"]),
          "metrics_salesFromNewCustomers_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.salesFromNewCustomers.dtcOnline"]),
          "metrics_blendedPaidCAC_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.blendedPaidCAC.dtcOnline"]),
          "metrics_newCustomersAcquired_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.newCustomersAcquired.dtcOnline"]),
          "metrics_averageOrderValue_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.averageOrderValue.dtcOnline"]),
          "drivers_returningCustomerAverageOrderValue_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.returningCustomerAverageOrderValue.dtcOnline"]),
          "drivers_newCustomerAverageOrderValue_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.newCustomerAverageOrderValue.dtcOnline"]),
          "drivers_blendedPaidCustomerAcquisitionCost_dtcOnline": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.blendedPaidCAC.dtcOnline"]),
          "metrics_blendedPaidCAC": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.blendedPaidCAC.dtcOnline"]),
          "metrics_newCustomersAcquired": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.newCustomersAcquired.dtcOnline"]),
          "metrics_salesFromReturningCustomers": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.salesFromReturningCustomers.dtcOnline"]),
          "metrics_salesFromNewCustomers": cleanExcelCellValues(pivotedDataExcelModelFinal[key]["metrics.salesFromNewCustomers.dtcOnline"]),
          scenarioName: sheetName,
          companyId: companyId,
          status: "active",
          stores: settings?.stores,
          createdAt: settings?.createdAt,
          currency: settings?.currency,
          settings: settings?.all
        });
      }
    }
  } catch (e) {
    let error_message = "writeSummationMetadataToScenariosTabOrCloud:Unable to Correctly write metadata:: Error Code 20 \n";
    logger.debug(error_message + e.toString());
  }
  return pivotedDataExcelModelReturn;
};

export const pivotDataForTableData = (dataExcelModel, sheetName: string, companyId: string, settings: any) => {
  let pivotedDataExcelModel = {};
  let pivotedDataExcelModelReturn = [];
  let finalExcelData = {};
  const friendlySheetName = getTabSlugFromFriendlyNameWithoutCapitalF(sheetName);
  try {
    logger.debug(`pivotDataForTableData going to pivot ${Object.keys(dataExcelModel).length} keys for ${sheetName}`);
    let metricFriendlyName = "";
    for (const metricName in dataExcelModel) {
      if (dataExcelModel.hasOwnProperty(metricName)) {
        pivotedDataExcelModel[metricName] = []; // was {}
        if (["modelStartYear", "modelStartDateInteger", "modelStartDateString", "threeYearPlanStartYear", "threeYearPlanStartDateInteger", "threeYearPlanStartDateString", "", "End"].includes(metricName)) {
          delete pivotedDataExcelModel[metricName];
          delete finalExcelData[metricName];
        } else {
          const monthlyRows = dataExcelModel[metricName]["values"];
          for (const monthRowKey in monthlyRows) {
            if (monthlyRows.hasOwnProperty(monthRowKey)) {
              let monthRow = monthlyRows[monthRowKey];
              let value = monthRow.valueSheet;
              let metricName = monthRow.metricName;
              const usingColumnBForIds = false; // if we're using column b and we need to coerce friendly names to ids, us this as true
              // else this uses column a if set to false since the perma ids are there..
              // set to true on a test run to re-generate the friendly ids, then go change the schema
              if (usingColumnBForIds) {
                metricName = metricName.replace(/ /g, "_"); // make it underscore case...
                metricName = metricName.replace(/\+/g, "_plus_");
                metricName = metricName.replace(/&/g, "_and_");
                metricName = metricName.replace(/:/g, "_");
                metricName = metricName.replace(/\//g, "_");
                metricName = metricName.replace(/-/g, "_");
                metricName = metricName.replace(/\(/g, "_");
                metricName = metricName.replace(/\)/g, "_");
                metricName = metricName.replace(/\[/g, "_");
                metricName = metricName.replace(/]/g, "_");
                metricName = metricName.replace(/__/g, "_");
                metricName = metricName.replace(/__/g, "_");
                metricName = metricName.replace(/__/g, "_");
                metricName = metricName.toLowerCase();
                metricName = metricName.replace(/_dtc_online$/g, "__dtconline");
                metricName = metricName.replace(/_wholesale$/g, "__wholesale");
                metricName = metricName.replace(/_retail/g, "__retail");
                metricName = metricName.replace(/_marketplace/g, "__marketplace");
                metricName = metricName.replace(/_$/g, ""); // remove trailing
                metricName = friendlySheetName + "___" + metricName;// add source
                metricName = metricName.toLowerCase(); // get rid of the capital F in financial
                if (metricFriendlyName !== metricName) {
                  logger.debug(metricName);
                  metricFriendlyName = metricName;
                }
              }
              let dateObject = getJsDateFromExcel(monthRowKey);
              let yearMonthNumeric = toYearMonthNumeric(dateObject);
              if (finalExcelData && finalExcelData[yearMonthNumeric]) {
              } else {
                finalExcelData[yearMonthNumeric] = {};
                finalExcelData[yearMonthNumeric]["yearMonthNumeric"] = yearMonthNumeric;
                finalExcelData[yearMonthNumeric]["id"] = yearMonthNumeric;
                finalExcelData[yearMonthNumeric]["companyId"] = companyId;
                finalExcelData[yearMonthNumeric]["stores"] = settings?.stores;
                finalExcelData[yearMonthNumeric]["createdAt"] = settings?.createdAt;
                finalExcelData[yearMonthNumeric]["currency"] = settings?.currency;
                finalExcelData[yearMonthNumeric]["settings"] = settings?.all;
              }
              if (typeof value === "number") {
                finalExcelData[yearMonthNumeric][metricName] = Math.round((value + Number.EPSILON) * 100) / 100;
              } else if (isInvalidCellValue(value)) { // broken links, make them zeros
                finalExcelData[yearMonthNumeric][metricName] = 0;
              } else {
                finalExcelData[yearMonthNumeric][metricName] = value;
              }
            }
          }
        }
      }
    }
    for (const key in finalExcelData) {
      if (key && key != "" && Object.keys(finalExcelData[key]).length) {
        pivotedDataExcelModelReturn.push(finalExcelData[key]);
      }
    }
  } catch (e) {
    logger.debug(e.message);
  }
  return pivotedDataExcelModelReturn;
};

export const executeGetTimeWindowsForDropdown = async (context, dataByMetric) => {
  try {
    OfficeExtension.config.extendedErrorLogging = true;
    // KeyDrivers.ModelStartDate  =Settings!$D$4
    logger.debug("executeGetTimeWindowsForDropdown Entry");
    let startYear = await getModelStartYear(context, "Settings", "settings", "ProFormaStartDate"); // moved from sheetNameDriversKey then changed from ModelStartDate to ProFormaStartDate comon guys..
    if (startYear.returnYear == 0) { // TODO: remove this when v4 is deprecated
      logger.debug("Falling back to get startYear for v4 models who have KeyDrivers");
      startYear = await getModelStartYear(context, "Settings", "KeyDrivers", "ModelStartDate"); // moved from sheetNameDriversKey
      logger.debug("new modelStartYear:" + startYear.returnYear);
    }
    dataByMetric["modelStartYear"] = startYear.returnYear;
    dataByMetric["modelStartDateInteger"] = startYear.returnDateInteger;
    dataByMetric["modelStartDateString"] = startYear.returnDateString;
  } catch (e) {
    logger.debug("final catch:" + e.toString());
  }
  return dataByMetric;
};

const getModelStartYear = async (context, sheetName, namedRangePrefix, namedRangeSuffix) => {
  let returnYear = 0;
  let returnDateInteger = 0;
  let returnDateString = "N/A";
  let returnObject = {
    returnYear,
    returnDateInteger,
    returnDateString
  };
  try {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let yearSheetRange = sheet.getRange(`${namedRangePrefix}.${namedRangeSuffix}`); // ='D - Key Drivers'!$C$12:$C$140
    context.trackedObjects.add(yearSheetRange); // the context.trackedObjects have to be right under the definition or you get a 'the object is untracked' error  General Error
    yearSheetRange.load("values, address, numberFormat");
    await context.sync();
    let fullDate = yearSheetRange.values;
    if (fullDate[0] && fullDate[0][0]) {
      returnObject.returnDateInteger = fullDate[0][0];
      returnObject.returnYear = getYearFromExcelDateInteger(fullDate[0][0]);
      let dateObject = getJsDateFromExcel(fullDate[0][0]);
      returnObject.returnDateString = toShortFormatDate(dateObject);
    } else {
      logger.debug("MODEL YEAR: could not figure it out! defaulting to: " + JSON.stringify(returnObject));
    }
  } catch (e) {
    let error_message = "Unable to Correctly get model year: Error Code 15 for namedrange: " + `${namedRangePrefix}.${namedRangeSuffix}`;
    logger.debug(error_message + e.toString());
  }
  return returnObject;
};

// export date as 2022-03-01
export function dateToYearMonthDay(date: Date, forceFirstOfMonth: boolean = false): string {
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = forceFirstOfMonth ? "01" : `${date.getDate()}`.padStart(2, "0");
  return `${date.getFullYear()}-${month}-${day}`;
}

export function dateToMonthYear(date: Date, forceFirstOfMonth: boolean = false): string {
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = forceFirstOfMonth ? "01" : `${date.getDate()}`.padStart(2, "0");
  return `${month}/${date.getFullYear()}`;
}

// export date as "2015/01/01"
export function dateToYearMonthDayWithSlashes(date: Date, forceFirstOfMonth: boolean = false): string {
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = forceFirstOfMonth ? "01" : `${date.getDate()}`.padStart(2, "0");
  return `${date.getFullYear()}/${month}/${day}`;
}

export function dateWithLastDayOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0);
}

export function dateAsLastDayOfPreviousMonth(date: Date): Date {
  return dateWithLastDayOfMonth(new Date(date.getFullYear(), date.getMonth() - 1, 1));
}

export function dateAsLastDayOfNextMonth(date: Date): Date {
  return dateWithLastDayOfMonth(new Date(date.getFullYear(), date.getMonth() + 1, 1));
}

export async function getSettings(context?: Excel.RequestContext): Promise<any> {
  return _settingsContext.mutex.runExclusive(async () => {
    if (_settingsContext.value && Date.now() - _settingsContext.timestamp < 60000) {
      logger.debug("using cached settings");
      return _settingsContext.value;
    }
    logger.debug("rebuilding settings...");
    _settingsContext.timestamp = Date.now();
    const mode = await ExcelUtilities.getCalculationMode(context);
    try {
      return ExcelUtilities.withOptionalContext(async (context: Excel.RequestContext) => {
        await ExcelUtilities.setCalculationMode(Excel.CalculationMode.manual, context);
        const worksheet = context.workbook.worksheets.getItem("Settings");
        const range = worksheet.getUsedRange(true);
        range.load("values");
        await context.sync();
        const pattern = /^settings[.]\w+$/;
        if (!range.values) { throw new Error("could not get range data"); }
        let settings = Object.assign({}, ...range.values
          .filter(value => pattern.test(value[1]))
          .map(value => ({
            [value[1]]: {
              values: {
                Value: {
                  valueSheet: typeof value[3] === "string" ? value[3].trim() : value[3]
                }
              }
            }
          }))
        );
        settings = buildSettingsFromRangeDataSettings(settings);
        if (!settings) { throw new Error("could not build settings object"); }
        _settingsContext.value = settings;
        logger.debug("caching settings");
        return settings;
      }, context);
    } finally {
      ExcelUtilities.setCalculationMode(mode, context);
      _settingsContext.timestamp = Date.now();
    }
  });
}

export function addUserInputToSettings(settings: any, sendInSlack: boolean = false, showPlanInDashboard: boolean = false): any {
  const settingsToAddFromUserInput = {
    executive_dashboard_status: showPlanInDashboard ? "active" : "deleted",
    email_status: "deleted",
    slack_status: sendInSlack ? "active" : "deleted"
  };
  settings = {...settings, ...settingsToAddFromUserInput};
  settings = simplifySettingsKeys(settings);
  settings.all = JSON.stringify(settings);
  return settings;
}

export function buildSettingsFromRangeDataSettings(settings: any, sendInSlack: boolean = false, showPlanInDashboard: boolean = false): any {
  settings = Object.keys(settings || {}).reduce((values: any, key: string) => ({...values, [key]: settings[key]?.values?.Value?.valueSheet}), {});
  settings.createdAt = new Date().toISOString().replace("T", " ").slice(0, 19) + " UTC";
  settings = addUserInputToSettings(settings, sendInSlack, showPlanInDashboard);
  return settings;
}

export function simplifySettingsKeys(settings: any): any {
  return Object.keys(settings).reduce((results: any, key: string) => {
    const value = settings[key];
    const match = key.match(/^settings\.(.+)/);
    if (match) { key = match[1][0].toLowerCase() + match[1].slice(1); }
    return {...results, [key]: value};
  }, {});
}

export async function getInternalDataStructureForRangeData(context: any, rangeData: IRangeData): Promise<any> {
  return buildInternalDataStructureForTab(
    context,
    {},
    rangeData.sheetName,
    rangeData.xAxisRange,
    rangeData.yAxisRange,
    rangeData.xAxisRowsToSkip,
    rangeData.yAxisRowsToSkip,
    rangeData.algorithm == "timeSeries"
  );
}

export async function buildInternalDataStructureForTab(context, dataByMetric: {}, sheetName, xAxisNamedRange, yAxisNamedRange, xAxisRowsToSkipFromHeaderToData, yAxisColumnsToSkipFromHeaderToData, timeSeries = true) {
  let rangeMetricsArray;
  let rangeDatesArray;
  await Excel.run(async (context) => {

    let sheet;
    let rangeDates;
    let rangeMetrics;
    let cellIndentObject: any = {};
    // https://learn.microsoft.com/en-us/javascript/api/excel/excel.numberformatcategory?view=excel-js-preview
    const metricsToGet = "values, address, columnCount, columnIndex, rowCount, rowIndex, name, names, formulas, format, valuesAsJson, numberFormat, numberFormatCategories";
    try {
      sheet = context.workbook.worksheets.getItem(sheetName);

      rangeDates = sheet.getRange(xAxisNamedRange); //  "KeyDrivers.ColumnDates" ='D - Key Drivers'!$C$12:$GZ$12
      context.trackedObjects.add(rangeDates);
      rangeDates.load(metricsToGet);
      await context.sync();
      rangeMetrics = sheet.getRange(yAxisNamedRange); // "KeyDrivers.MetricLabels" ='D - Key Drivers'!$C$12:$C$140
      context.trackedObjects.add(rangeMetrics); // the context.trackedObjects have to be right under the definition or you get a 'the object is untracked' error  General Error
      rangeMetrics.load(metricsToGet);
      const sheetRange = sheet.getUsedRange();
      sheetRange.load("address, columnIndex, columnCount, rowCount, rowIndex");

      await context.sync();
      // const cell = context.workbook.getActiveCell();
      // for M- Monthly get all the indentLevel for column C
      // limited for now because this takes time to walk through cell by cell, we would NOT want to do all tabs and all cells!!
      if (sheetName === "M - Monthly") {
        const maxUsedRowNumber = sheetRange.rowCount;
        let indexOfCell = 0;
        while (indexOfCell <= maxUsedRowNumber) {
          const cell = sheet.getCell(indexOfCell, 2);
          cell.load("values, text");
          // Define the cell properties to get by setting the matching LoadOptions to true.
          const propertiesToGet = cell.getCellProperties({
            address: true,
            format: {
              indentLevel: true,
              fill: {
                color: true,
                pattern: true,
                patternColor: true,
                patternTintAndShade: true,
                tintAndShade: true
              },
              font: {
                bold: true,
                color: true,
                italic: true,
                name: true,
                size: true,
                strikethrough: true,
                subscript: true,
                superscript: true,
                tintAndShade: true,
                underline: true
              }
            },
            style: true
          });
          indexOfCell++;
          // Sync to get the data from the workbook.
          await context.sync();
          const cellProperties = propertiesToGet.value[0][0];
          let rowNumber = cellProperties.address.replace("'M - Monthly'!C", "");
          let cellText = cell.text[0];
          let cellValue = cell.values[0];
          cellIndentObject[cellText] = {
            "indentLevel": cellProperties.format.indentLevel,
            "rowNumber": rowNumber,
            "address": cellProperties.address,
            "text": cellText,
            "value": cellValue,
            "format": cellProperties.format,
            "style": cellProperties.style
          };
        }
      }
      await context.sync();
    } catch (error: any) {
      logger.debug("buildInternalDataStructureForTab" + error.message);
      throw new Error(`Could not find the "${sheetName}" tab, please check the Ranges tab.`);
    }

    // Dates are in days since 1/1/1900 of course
    /*
            12/31/24	45657	leap year
            12/31/23	45291
            12/31/22	44926
            12/31/21	44561
            12/31/20	44196	leap year
            12/31/19	43830
            12/31/18	43465
            12/31/17	43100
            12/31/16	42735	leap year
             */
    // let datesAddress = rangeDates.address; // "'M - Monthly'!DI12:GZ12"
    let metricsAddress = rangeMetrics.address;
    // let startingColumn = rangeDates.columnIndex; // the first column we have
    // let startingRow = rangeMetrics.rowIndex; // the first row we have
    // InvalidObjectPath: The object path '_reference().getBoundingRect()' isn't working for what you're trying to do. If you're using the object across multiple "context.sync" calls and outside the sequential execution of a ".run" batch, please use the "context.trackedObjects.add()" and "context.trackedObjects.remove()" methods to manage the object's lifetime.
    // get the bounding rectangle of the headers
    // somehow range ends up being 1 cell wider after the getBoundingRect call...
    let range = rangeDates.getBoundingRect(metricsAddress);

    context.trackedObjects.add(range);
    range.load("address");
    await context.sync();

    const properties = await ExcelUtilities.getPropertiesForRange(sheet, range, "values", "formulas", "valuesAsJson", "numberFormat", "numberFormatCategories");

    rangeMetricsArray = rangeMetrics.values;
    rangeDatesArray = rangeDates.values;

    // TODO: this is workable for now, but super slow as we are calling an excel "cell" function on each cell.
    // hoping it would be fast but its not, so we'll need to SIGH, keep a count of the range ourselves and populate a cell Matrix array :(
    // for the entire bounding box
    // grab the top left
    // As you traverse across and down, do the increment thing
    // for (let metricIndex = 0; metricIndex < range.rowCount; metricIndex++) { // Dates, then blank line
    //     cellMatrix[metricIndex]=[];
    //     for (let dateIndex = 0; dateIndex < range.columnCount; dateIndex++) { // Dates then 2 headers
    //         let cell = range.getCell(metricIndex, dateIndex);
    //         cell.load(metricsToGet);
    //         cellMatrix[metricIndex][dateIndex] = cell;
    //     }
    // }
    await context.sync();

    /* faster home grown solution..
            takes the sheet, given the appropriate outer ranges turned into a bounding box and builds an array of all the cell addresses in there ie
            [
              [B7, C7, D7... GZ7],
              ...
              [B123, C123, D123... G123],

            this is required below if we want to know WHERE each cell is, since you can't interrogate the data points, and we do want to know where the data was
            so we build our own map of the cell layout or the cellMatrixNew

         */

    let cellMatrixNewInner = [];
    let cellMatrixNew = [cellMatrixNewInner];
    let startingCellObject = getFrontAndBackOfRangeFromAddress(range.address);
    let topCorner = startingCellObject.start;
    for (let metricIndex = 0; metricIndex < range.rowCount; metricIndex++) { // Dates, then blank line
      cellMatrixNew[metricIndex] = [];
      for (let dateIndex = 0; dateIndex < range.columnCount; dateIndex++) { // Dates then 2 headers
        cellMatrixNew[metricIndex][dateIndex] = advanceCellLettersOrNumbers(topCorner, dateIndex, metricIndex);
      }
    }

    /* ok so cellMatrix has all the properties of each cell, mainly we can get the cell address easily here.
            cellMatrix[5][5].address
                < "'D - Key Drivers'!H17"
            cellMatrix[5][5].values
                [[171]] (1)
            cellMatrix[5][5].values[0][0]
                17
             */

    let innerarray = [];
    let annualSummary = [innerarray];
    let annualRollupInnerArray = [];
    let annualRollup = [annualRollupInnerArray];
    // In the range there are some garbage rows and columns we want to skip..  make sure to back them out of any loops...
    // C12 is where this is referenced from..
    // we shift to G16 which is where the data actually begins for the current model
    const metricRowsToSkip = xAxisRowsToSkipFromHeaderToData;// 4 for key drivers
    const dateColumnsToSkip = yAxisColumnsToSkipFromHeaderToData; // 4;

    /*
            Here we index into the raw data structure returned by excel skipping the rows that we know are not useful.
            We set the 'firstIndex' to be the durable id always, ie the first entry in the row
            we set the 'firstIndexFriendlyName' to be the friendly name, dear god let it be the second column over.. if not we can add an offset later if we feel like it

         */
    for (let metricIndex = metricRowsToSkip; metricIndex < rangeMetricsArray.length; metricIndex++) { // Dates, then blank line ///  range.rowCount
      annualSummary[metricIndex] = []; // need to init the outer array here
      annualRollup[metricIndex] = [];
      const firstIndex = rangeMetricsArray[metricIndex][0];
      const firstIndexFriendlyName = properties.values[metricIndex][1];
      // let friendlyName = rangeMetricsArray[metricIndex+1][0];
      // ignore any empty keys, no need to store them
      if (!firstIndex || firstIndex == "" || (typeof firstIndex == "string" && firstIndex.trimRight().trimLeft() == "")) {
        // logger.debug("Skipping empty key!! at "+ cellMatrixNew[metricIndex][0]);
        continue;
      }
      dataByMetric[firstIndex] = {}; // V2

      // row headers will get stored here
      let headerObject: any = {};
      if (sheetName === "M - Monthly") {
        if (firstIndexFriendlyName in cellIndentObject) {
          headerObject.rowNumber = cellIndentObject[firstIndexFriendlyName]?.rowNumber; // only available on column 'C' for names in M-Monthly now
          headerObject.indentLevel = cellIndentObject[firstIndexFriendlyName]?.indentLevel; // only available on column 'C' for names in M-Monthly now
          headerObject.style = cellIndentObject[firstIndexFriendlyName]?.style;
          headerObject.format = cellIndentObject[firstIndexFriendlyName]?.format;
          headerObject.address = cellIndentObject[firstIndexFriendlyName]?.address;
          headerObject.metricName = firstIndexFriendlyName;
          headerObject.metricId = firstIndex;
        }  else { // be safe for malformed monthly
          headerObject.rowNumber = 0;
          headerObject.indentLevel = 0;
          headerObject.style = "";
          headerObject.format = "";
          headerObject.address = "";
          headerObject.metricName = firstIndexFriendlyName;
          headerObject.metricId = firstIndex;
        }
        if (!dataByMetric[firstIndex]?.rowMetadataByName) {
          dataByMetric[firstIndex]["rowMetadataByName"] = {};
          dataByMetric[firstIndex]["rowMetadataById"] = {};
        }
        dataByMetric[firstIndex]["rowMetadataByName"][firstIndexFriendlyName] = {};
        dataByMetric[firstIndex]["rowMetadataById"][firstIndex] = {};
        dataByMetric[firstIndex]["rowMetadataByName"] = headerObject;
        dataByMetric[firstIndex]["rowMetadataById"] = headerObject;
      }

      for (let dateIndex = dateColumnsToSkip; dateIndex < rangeDatesArray[0].length; dateIndex++) { // Dates then 2 headers /// range.columnCount
        const value = cleanExcelCellValues(properties.values[metricIndex][dateIndex]); // offset for the width of headers
        const numberType = properties.valuesAsJson[metricIndex][15]?.type; // row k has data and a format most all times
        const numberBasicType = properties.valuesAsJson[metricIndex][15]?.basicType; // row k has data most all times
        const numberFormat = properties.numberFormat[metricIndex][15]; // row k has data most all times
        const numberFormatCategory = properties.numberFormatCategories[metricIndex][15]; // row k has data most all times
        const formula = properties.formulas[metricIndex][dateIndex];
        // put these into the row level metadata object also...
        if (sheetName == "M - Monthly") {
          if (dateIndex == 15) { // technically k is column 9, so go a bit past that to be safe
            dataByMetric[firstIndex]["rowMetadataByName"]["numberType"] = numberType;
            dataByMetric[firstIndex]["rowMetadataById"]["numberType"] = numberType;
            dataByMetric[firstIndex]["rowMetadataByName"]["numberBasicType"] = numberBasicType;
            dataByMetric[firstIndex]["rowMetadataById"]["numberBasicType"] = numberBasicType;
            dataByMetric[firstIndex]["rowMetadataByName"]["numberFormat"] = numberFormat;
            dataByMetric[firstIndex]["rowMetadataById"]["numberFormat"] = numberFormat;
            dataByMetric[firstIndex]["rowMetadataByName"]["numberFormatCategory"] = numberFormatCategory;
            dataByMetric[firstIndex]["rowMetadataById"]["numberFormatCategory"] = numberFormatCategory;
            dataByMetric[firstIndex]["rowMetadataByName"]["formula"] = formula;
            dataByMetric[firstIndex]["rowMetadataById"]["formula"] = formula;
          }
        }
        const cellAddressString = cellMatrixNew[metricIndex][dateIndex];
        annualSummary[metricIndex][dateIndex] = value;
        const secondIndex = rangeDatesArray[0][dateIndex];
        // this would be cool but... getting this year function to work in here without 1000s of syncs..
        // let yearFunction= context.workbook.functions.year(secondIndex);
        // let year = yearFunction.load('value');
        if (!dataByMetric[firstIndex]["metricFriendlyName"]) {
          dataByMetric[firstIndex]["metricFriendlyName"] = firstIndexFriendlyName;
        }
        if (!dataByMetric[firstIndex]["firstCellAddress"]) {
          dataByMetric[firstIndex]["firstCellAddress"] = cellAddressString;
        }
        if (timeSeries) {
          // in things like the products table, its possible that this isnt a date integer... ugh. so yeah
          // so we try to do date coercion and if not, just store it as text..
          let monthObject = {};
          let dateString;
          let yearMonthNumeric;
          let year;
          let dateObject;
          if (typeof secondIndex == "number") {
            year = getYearFromExcelDateInteger(secondIndex); // 1900 + Math.floor((secondIndex-1) / 365.25); // yeahhhhh
            if (year < 2000) {
              continue; // this means it is a row without a real date header, just skip it..
            }
            if (!dataByMetric[firstIndex]["y" + year]) { // V2 })
              dataByMetric[firstIndex]["y" + year] = {}; // V2
              dataByMetric[firstIndex]["y" + year]["rangeStart"] = cellAddressString; // V2
              // dataByMetric[firstIndex]["y" + year]['childArray'] = []; // V2
            }
            dataByMetric[firstIndex]["y" + year]["rangeEnd"] = cellAddressString;
            if (!dataByMetric[firstIndex]["months"]) {
              dataByMetric[firstIndex]["months"] = {};
            }
            if (!dataByMetric[firstIndex]["months"]["childArray"]) {
              dataByMetric[firstIndex]["months"]["childArray"] = {};
            }
            dateObject = getJsDateFromExcel(secondIndex);
            dateString = toShortFormatDate(dateObject);
            yearMonthNumeric = toYearMonthNumeric(dateObject);
          } else {
            year = "";
            if (!dataByMetric[firstIndex]["y" + year]) { // V2 })
              dataByMetric[firstIndex]["y" + year] = {}; // V2
              dataByMetric[firstIndex]["y" + year]["rangeStart"] = cellAddressString; // V2
              // dataByMetric[firstIndex]["y" + year]['childArray'] = []; // V2
            }
            dataByMetric[firstIndex]["y" + year]["rangeEnd"] = cellAddressString;
            if (!dataByMetric[firstIndex]["months"]) {
              dataByMetric[firstIndex]["months"] = {};
            }
            if (!dataByMetric[firstIndex]["months"]["childArray"]) {
              dataByMetric[firstIndex]["months"]["childArray"] = {};
            }
            dateObject = "";
            dateString = "";
            yearMonthNumeric = "";
          }
          monthObject["formulaSheet"] = formula;
          monthObject["valueSheet"] = value;
          monthObject["cellAddress"] = cellAddressString;
          monthObject["dateYear"] = year;
          monthObject["dateString"] = dateString;
          monthObject["dateInteger"] = secondIndex;
          monthObject["metricName"] = firstIndex;
          monthObject["metricFriendlyName"] = firstIndexFriendlyName;
          monthObject["yearMonthNumeric"] = yearMonthNumeric;
          monthObject["numberType"] = numberType;
          monthObject["numberBasicType"] = numberBasicType;
          monthObject["numberFormat"] = numberFormat;
          monthObject["numberFormatCategory"] = numberFormatCategory;
          dataByMetric[firstIndex]["months"]["childArray"][dateString] = monthObject; // this is where ALLL the size is here.. 500kb per childArray roughly..
          if (value != undefined && !isNaN(value) && value) {
            if (!dataByMetric[firstIndex]["y" + year] || isNaN(dataByMetric[firstIndex]["y" + year])) {
              dataByMetric[firstIndex]["y" + year]["sum"] = 0; // V2
              dataByMetric[firstIndex]["y" + year]["sum"] = value; // V2
              dataByMetric[firstIndex]["y" + year]["average"] = 0; // V2
              dataByMetric[firstIndex]["y" + year]["average"] = value; // V2
              dataByMetric[firstIndex]["y" + year]["lastValue"] = value; // V2
              dataByMetric[firstIndex]["y" + year]["childCount"] = 1; // V2
              dataByMetric[firstIndex]["y" + year]["countOfDifferent"] = 0; // V2
            } else {
              dataByMetric[firstIndex]["y" + year]["sum"] += value; // V2
              dataByMetric[firstIndex]["y" + year]["childCount"]++; // V2
              // average here is hard..... since we may have lots of cells out there
              dataByMetric[firstIndex]["y" + year]["average"] = (dataByMetric[firstIndex]["y" + year]["sum"]) / dataByMetric[firstIndex]["y" + year]["childCount"]; // V2
              if (dataByMetric[firstIndex]["y" + year]["lastValue"] != value) { // HAHA ok so you cant compare the average to the value here as javascript is awful at floats..  so use the 'lastValue'
                dataByMetric[firstIndex]["y" + year]["countOfDifferent"]++; // V2
              }
              dataByMetric[firstIndex]["y" + year]["lastValue"] = value; // V2
            }
          }
          if (dataByMetric[firstIndex]["y" + year]["countOfDifferent"] == 0) { // all values are uniform so let set a valueSheet at the year level
            dataByMetric[firstIndex]["y" + year]["valueSheet"] = value;
          }
        } else {
          let fieldObject = {};
          fieldObject["formulaSheet"] = formula;
          fieldObject["valueSheet"] = value;
          fieldObject["cellAddress"] = cellAddressString;
          fieldObject["metricName"] = firstIndex;
          fieldObject["metricFriendlyName"] = firstIndexFriendlyName;
          fieldObject["rowNumber"] = cellIndentObject?.value?.rowNumber;
          fieldObject["indentLevel"] = cellIndentObject?.value?.indentLevel;
          fieldObject["numberType"] = numberType;
          fieldObject["numberBasicType"] = numberBasicType;
          fieldObject["numberFormat"] = numberFormat;
          fieldObject["numberFormatCategory"] = numberFormatCategory;
          fieldObject["style"] = cellIndentObject?.value?.style; // only available on column 'C' for names in M-Monthly now
          fieldObject["format"] = cellIndentObject?.value?.format; // only available on column 'C' for names in M-Monthly now
          if (!dataByMetric[firstIndex]["values"]) {
            dataByMetric[firstIndex]["values"] = {};
          }
          dataByMetric[firstIndex]["values"][secondIndex] = fieldObject;
        }
      }
    }
    if (timeSeries) {
      executeGetTimeWindowsForDropdown(context, dataByMetric);
    }
  })
    .catch(function(error) {
      logger.debug("Error2: " + error);
      Sentry.captureException(error);
      if (error instanceof OfficeExtension.Error) {
        logger.debug("Office Error2: " + error);
      }
      throw error;
    });
  return dataByMetric;
}

export const getRangeDataFromExcelTab = async (context: any): Promise<{ [key: string]: IRangeData }> => {
  let rangeData = {};
  await buildInternalDataStructureForTab(
    context,
    rangeData,
    Constants.SheetNameRanges,
    Constants.SheetNameRangesXAxisNamedRange,
    Constants.SheetNameRangesYAxisNamedRange,
    Constants.SheetNameRangesXAxisRowsToSkipFromHeaderToData,
    Constants.SheetNameRangesYAxisColumnsToSkipFromHeaderToData,
    false);
  let sheetsToWorkOn: Record<string, any> = {};
  let sheetsKey = "";
  let allRangeData = {};
  for (sheetsKey in rangeData) {
    sheetsToWorkOn = rangeData[sheetsKey];
    if (!sheetsKey || sheetsKey === "") { // empty rows get pushed here too for large ranges
      continue;
    }
    if (!("values" in sheetsToWorkOn && "sheetName" in sheetsToWorkOn["values"])) {
      logger.debug("bad sheet name!! skipping" + sheetsKey);
      continue;
    }
    const sheetName = sheetsToWorkOn["values"]["sheetName"]["valueSheet"];
    const rangeId = sheetsToWorkOn["values"]["sheetName"]["metricName"];// here take the 'raw' id representation, not the actual name, ie the id keyDrivers instead of Key Drivers
    // the Ranges tab in the sheet must have an ID column of ONLY cameCase.  if you put in dots you get arbitrary hierarchy levels in the code later..
    // in fact we HAVE to use the id since sheetName for products (since it has two ranges) looses data here..
    let xAxisRange = sheetsToWorkOn["values"]["xAxisRange"]["valueSheet"];
    let yAxisRange = sheetsToWorkOn["values"]["yAxisRange"]["valueSheet"];
    // the ranges like to loose the leading ' which is required, so check and add it back
    if (!xAxisRange.startsWith("'")) {
      xAxisRange = "'" + xAxisRange;
    }
    if (!yAxisRange.startsWith("'")) {
      yAxisRange = "'" + yAxisRange;
    }
    const xAxisRowsToSkip = sheetsToWorkOn["values"]["xAxisRowsToSkip"]["valueSheet"];
    const yAxisRowsToSkip = sheetsToWorkOn["values"]["yAxisColumnsToSkip"]["valueSheet"];
    const algorithm = sheetsToWorkOn["values"]["algorithm"]["valueSheet"];
    const formulasOrValues = sheetsToWorkOn["values"]["formulasOrValues"]["valueSheet"];
    const rangeDisplayName = sheetsToWorkOn["values"]["rangeDisplayName"]["valueSheet"];
    const rehydrate = getBooleanValue(sheetsToWorkOn["values"]["rehydrate"]["valueSheet"]);
    const rollForward = getBooleanValue(sheetsToWorkOn?.values?.rollForward?.valueSheet);
    allRangeData[rangeId] = {
      rangeId,
      sheetName,
      rangeDisplayName,
      xAxisRange,
      yAxisRange,
      xAxisRowsToSkip,
      yAxisRowsToSkip,
      algorithm,
      rehydrate,
      formulasOrValues,
      rollForward
    };
  }
  return allRangeData;
};

export async function validateRangeSheet(): Promise<void> {

  return await Excel.run(async (context) => {
    const rangesSheet = await getWorksheet(context, "Ranges");

    if (!rangesSheet) {
      throw Error("Can not find 'Ranges' sheet");
    }

    const sheetNames = await getWorksheetNames(context);
    const sheetNamesMap = sheetNames.reduce((newMap, el) => ({...newMap, [el]: el}), {});

    const rangeFromRangesSheet = rangesSheet.getUsedRange();
    rangeFromRangesSheet.load("values");
    await context.sync();
    
    const sheetNamesListedInRanges = rangeFromRangesSheet.values
      .map((row) => row[1])
      .slice(1);

    for (let name of sheetNamesListedInRanges) {
      if (!sheetNamesMap[name]) {
        throw Error(`We could not find '${name}' tab that you indicated in the R-Ranges tab`);
      }
    }

  });
}

// Given any cell range reference range including a fulll sheet reference or not, return a starting cell as a string
// 'foobarsheet'!A22:N44
// return
//  start =>A22, end N22
export const getFrontAndBackOfRangeFromAddress = (addressString) => {
  let returnObject = {
    start: "",
    end: ""
  };
  const found = addressString.match(/!(\w{1,}\d{1,}):(\w{1,}\d{1,})/);
  const foundSimple = addressString.match(/(\w{1,}\d{1,}):(\w{1,}\d{1,})/);
  if (found && found[1] && found[2]) {
    returnObject.start = found[1];
    returnObject.end = found[2];
  } else if (foundSimple && foundSimple[1]) {
    returnObject.start = foundSimple[1];
    returnObject.end = foundSimple[2];
  } else {
    logger.debug("could not find range from:" + addressString);
  }
  return returnObject;
};

// turn 44427 aka 1/31/2021 into 2021...
export const getYearFromExcelDateInteger = (dateInteger) => {
  return 1900 + Math.floor((dateInteger - 1) / 365.25); // yeahhhhh
};

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// default in Bigquery is leading zero
const monthNumberToMonthStringTwoDigits = {
  1: "01",
  2: "02",
  3: "03",
  4: "04",
  5: "05",
  6: "06",
  7: "07",
  8: "08",
  9: "09",
  10: "10",
  11: "11",
  12: "12"
};

// given a date object return the friendly date 'Apr - 2020'
export const toShortFormatDate = function(date) {
  let monthIndex = date.getMonth();
  let monthName = monthNames[monthIndex];
  let year = date.getFullYear();
  return `${monthName} - ${year}`;
};

// given a date object return the date needed in bigQuery for the Exec dashboard 2021_08
export const toYearMonthNumeric = function(date) {
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return `${year}_${monthNumberToMonthStringTwoDigits[monthIndex + 1]}`;
};

export function getExcelDateSerialNumberFromDate(date: Date): number {
  // paraphrased from https://stackoverflow.com/a/70840742/19596974
  // the .1 is necessary to get the very last day of a month. without it for Jan 2023 this function would return 01/30/2023 instead of 01/31/2023
  return Math.floor(25569.1 + (date.getTime() / 86400000));
}
// https://stackoverflow.com/questions/31343129/convert-excel-datevalue-to-javascript-date

/*
 WARNING: This function produces wrong results at least in the easter hemisphere. Consider using the function below instead.
 */
export const getJsDateFromExcel = function(excelDate: any): Date {
  // JavaScript dates can be constructed by passing milliseconds
  // since the Unix epoch (January 1, 1970) example: new Date(12312512312);
  // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1  (Google "excel leap year bug")
  // 2. Convert to milliseconds.
  return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
};

// 2022/04/01 to 2022/04/31
export const getJsDateFromSlashDateAsEomDay = function(date) {
  const current_date_array = date.split("/");
  return new Date(current_date_array[0], current_date_array[1], 0); // day of 0 forces Date() to use last day of month
};

// tabnames can ONLY be 31 characters in excel.. yeah...
export const getTabSlugFromFriendlyNameWithoutCapitalF = (tabName) => {
  tabName = tabName.replaceAll("F -", "");
  tabName = tabName.replaceAll("R -", "");
  tabName = tabName.replaceAll(" ", "");
  tabName = tabName.replaceAll("-", "_");
  tabName = tabName.replaceAll("F_", "");
  tabName = tabName.replaceAll("R_", "");
  if (tabName.length > 31) {
    tabName = tabName.substring(0, 31);
  }
  return tabName;
};

export const isVersionGreaterOrEqualTo = (versionStringInProduct, versionString) => {
  return compareVersions(versionStringInProduct, versionString) >= 0;
};

export const isToTheRightOf = (cellAddressRight, cellAddressLeft) => {
  return advanceCellLettersOrNumbers(cellAddressLeft, 1, 0) == cellAddressRight;
};

export function cleanExcelCellValues(value) {
  if (isInvalidCellValue(value)) {
    return 0;
  }
  if (value == "-") {
    return "0";
  }
  return value;
}

/**
 * Get all worksheets in a workbook, or just those with a name that matches a RegExp filter.
 * @param context {Excel.RequestContext} Excel context
 * @param nameFilter {RegExp} optional RegExp to filter names
 * @returns {Worksheet[]}
 */
export async function getWorksheets(context: any, nameFilter?: RegExp): Promise<Worksheet[]> {
  try {
    const worksheets = context.workbook.worksheets;
    worksheets.load("items");
    await context.sync();
    return worksheets.items.filter(worksheet => {
      worksheet.load("name");
      return nameFilter ? !!worksheet.name.match(nameFilter) : true;
    });
  } catch (error: any) {
    logger.error(error.message);
    return [];
  }
}

/**
 * Get a single worksheet in a workbook by name.
 */
export async function getWorksheet(context: any, name: string, ...properties: string[]): Promise<Worksheet> {
  const worksheets = await getWorksheets(context);
  const worksheet = worksheets.find((worksheet: Worksheet) => worksheet.name === name);
  if (worksheet && properties.length) {
    worksheet.load(`${properties.flat().join(",")}`);
    await context.sync();
  }
  return worksheet;
}

/**
 * Get the name of all worksheets in a workbook, or just those with a name that matches a RegExp filter.
 * @param context {Excel.RequestContext} Excel context
 * @param nameFilter {RegExp} optional RegExp to filter names
 * @returns {string[]}
 */
export async function getWorksheetNames(context: any, nameFilter?: RegExp): Promise<string[]> {
  const worksheets = await getWorksheets(context, nameFilter);
  return worksheets.map((worksheet: Worksheet) => worksheet.name);
}

export async function sleep(ms: number = 0): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function executeJavascript(code: string, globals: any = {}): any {
  try {
    const context = {...globals, window: {}, document: {}, globalThis: {}};
    const body = [
      `${Object.keys(context).map(it => `const ${it}=${JSON.stringify(context[it])}`).join(";")}`, // globals
      code
    ];
    return Function(body.join(";"))();
  } catch (error: any) {
    throw new Error(`${error.message}: ${code}`);
  }
}

export async function getModelVersion(): Promise<string> {
  const settings = await getSettings();
  return settings["modelVersion"] || "4.0.0.0";
}

/**
 * From a multidimensional array that represent worksheet values, extract child array(row data) that contains the lookup string.
 * Return the row index the row data array itself.
 *
 * @param worksheetValues - multidimensional array
 * @param lookup - lookup string
 */
export function extractRowFromWorksheetValues(worksheetValues: any[], lookup: string): { index: number, row: any[] } {
  for (const [index, row] of worksheetValues.entries()) {
    const stringToExtract = row.find(value => typeof value === "string" && value.trim().toLowerCase() === lookup.trim().toLowerCase());
    if (stringToExtract) {
      return {index, row};
    }
  }
  return {index: -1, row: []};
}

export function getBooleanValue(input: string | number | boolean | undefined): boolean {
  if (typeof input === "boolean") {
    return input;
  } else if (input && typeof input === "string" && (input.trim().toLowerCase() === "true" || input.trim().toLowerCase() === "1")) {
    return true;
  } else if (input && typeof input === "number" && input === 1) {
    return true;
  }
  return false;
}
