/**
 * Copyright ©2024 Drivepoint
 */

import React, {ReactNode, useEffect, useRef} from "react";
import {Config, EventBus, useStateChange, useStore} from "@bainbridge-growth/node-frontend";
import PlanStore from "../../stores/PlanStore.ts";
import type {PlanProps} from "../../types/ExcelModel.ts";
import ErrorHandling from "../../utilities/ErrorHandling.ts";
import DPPageStatus, {DPPageStatusInterface} from "../DPPageStatus/DPPageStatus.tsx";
import "./Page.css";

export type PageProps = {
  children: ReactNode;
};

export default function Page(props: PageProps): any {

  const company =  useStateChange<any>("company");

  const status = useRef<DPPageStatusInterface>();
  const [plan] = useStore<PlanProps, PlanStore>(PlanStore);

  useEffect(() => {
    const registrations = EventBus.registerMany("system:error", (event: any) => {
      if (event.type === "system:error") {
        handleErrorStatus(event.message, event.refresh);
      }
      if (event.type === "system:error:compatibility") {
        handleErrorStatus(event.message, event.refresh);
      }
    });
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  useEffect(() => {
    if (status) { status.current?.hide(); }
  }, [location.hash]);

  useEffect(() => {
    if (plan.id === "not_smartmodel") {
      status.current.show({
        variant: "warning",
        title: "Compatibility Issue",
        subtitle: "Some features are disabled because this plan is not compatible with the Drivepoint SmartModel™️",
        actions: [{
          notHideAfter: true,
          icon: "open_in_new",
          title: "Browse other Plans",
          click: () => { window.open(`${Config.get("webapp.url")}/${company?.id ? `${company?.id}/plans` : ""}`, "_blank"); }
        },
        {
          notHideAfter: true,
          icon: "help",
          title: "Help center",
          click: () => { window.open("https://docs.drivepoint.io/help", "_blank"); }
        }]
      });
    } else {
      status.current?.hide();
    }
  }, [plan.id, location.hash]);

  function handleErrorStatus(message: string, refreshAction: () => void = () => window.location.reload()) {
    status.current?.show({
      variant: "error",
      title: "Something went wrong!",
      subtitle: `${message}\nPlease try again. If the issue persists please contact support.`,
      actions: [
        {icon: "refresh", title: "Refresh Add-in", click: refreshAction},
        {icon: "support_agent", title: "Contact Support", click: ErrorHandling.contactSupport, notHideAfter: true}
      ]
    });
  }

  return <div className="page">
    <DPPageStatus ref={status} />
    {props.children}
  </div>;

}
