/**
 * Copyright ©2024 Drivepoint
 */

import {GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup} from "firebase/auth";
import Firebase from "../../services/Firebase.ts";
import Telemetry from "../../services/telemetry/Telemetry.ts";

type SignInWithGoogleProps = {
  onError: (error: Error) => void;
};

type SignInWithCredentialsProps = {
  values: {email: string, password: string};
  onError: (error: Error) => void;
};

async function signInWithCredentials(props: SignInWithCredentialsProps) {
  const {email, password} = props.values;
  try {
    Telemetry.track("sign_in_with_email", {user_email: email});
    await signInWithEmailAndPassword(Firebase.auth, email, password);
  } catch (err) {
    props.onError(err);
  }
}

async function signInWithGoogle(props: SignInWithGoogleProps) {
  const provider = new GoogleAuthProvider();
  try {
    Telemetry.track("sign_in_with_google");
    await signInWithPopup(Firebase.auth, provider);
  } catch (err) {
    props.onError(err);
  }
}

export {signInWithGoogle, signInWithCredentials};
